<script setup lang="ts">
const { closeModal } = useModalStore();
const { isLoading, setLoadingPending, setLoadingSuccess, setLoadingFailed } =
  useLoading();
const $api = useApi();

const props = defineProps({
  foodSubFamily: {
    type: Object,
    default: () => {},
    required: true,
  },
  foodSubFamilyPortion: {
    type: Object,
    default: () => {},
  },
  onConfirm: {
    type: Function,
    default: () => {},
  },
});

const selectedPortionId = ref();
const foodPortionData = reactive({
  label: undefined,
  quantity: undefined,
});

watch(
  () => props.foodSubFamilyPortion,
  () => {
    selectedPortionId.value = props.foodSubFamilyPortion.portion.id;
    foodPortionData.label =
      props.foodSubFamilyPortion.overrideLabel ?? undefined;
    foodPortionData.quantity =
      props.foodSubFamilyPortion.overrideQuantity ?? undefined;
  },
  { immediate: true, deep: true },
);

async function handleUpdatePortionFromFoodSubFamily() {
  await $api.put(
    `/food/subfamily/${props.foodSubFamily.id}/portions/${props.foodSubFamilyPortion.id}`,
    {
      overrideLabel: foodPortionData.label,
      overrideQuantity: foodPortionData.quantity,
    },
  );
  props.onConfirm(props.foodSubFamilyPortion.id);
  closeModal();
}

async function handleDeletePortionFromFoodSubFamily() {
  await $api.delete(
    `/food/subfamily/${props.foodSubFamily.id}/portions/${props.foodSubFamilyPortion.id}`,
  );
  props.onConfirm(props.foodSubFamilyPortion.id);
  closeModal();
}
</script>

<template>
  <BaseModal
    :title="`Editer une portion de la sous-famille${props.foodSubFamily.label}`"
    :width="900"
  >
    <BaseInput
      type="text"
      label="Override Label"
      v-model="foodPortionData.label"
      :placeholder="props.foodSubFamilyPortion.label"
      required
    ></BaseInput>

    <BaseInput
      type="number"
      label="Override Quantité"
      v-model="foodPortionData.quantity"
      required
    ></BaseInput>

    <template #footer>
      <BaseButton text="Annuler" type="secondary" @click="closeModal" />

      <BaseButton
        text="Supprimer"
        type="warning"
        @click="handleDeletePortionFromFoodSubFamily"
      />
      <BaseButton
        text="Mettre à jour"
        @click="handleUpdatePortionFromFoodSubFamily"
      />
    </template>
  </BaseModal>
</template>

<style scoped></style>
